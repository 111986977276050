import React, { Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import FrontLayout from '../FrontLayout/FrontLayout'
import Home from '../component/Home'
import Aboutus from '../component/Aboutus'
import Chairman from '../component/Chairman'
import BoardofDirectors from '../component/BoardofDirectors'
import KamleshSharma from '../component/KamleshSharma'
import SureshSharma from '../component/SureshSharma'
import VikassharmaNew from '../component/VikassharmaNew'
import SheelaSharma from '../component/SheelaSharma'
import Yogeshgupta from '../component/Yogeshgupta'
import GovindMaherwal from '../component/GovindMaherwal'
import BoardCommittees from '../component/BoardCommittees'
import Services from '../component/Services'
import FinancialsReports from '../component/FinancialsReports'
import AnnualReturn from '../component/AnnualReturn'
import Prospectus from '../component/Prospectus'
import MaterialDocuments from '../component/MaterialDocuments'
import MaterialContracts from '../component/MaterialContracts'
import CorporateAnnouncements from '../component/CorporateAnnouncements'
import CorporateGovernanceReport from '../component/CorporateGovernanceReport'
import ShareholdingPattern from '../component/ShareholdingPattern'
import InvestorContacts from '../component/InvestorContacts'
import Policies from '../component/Policies'
import Disclosures from '../component/Disclosures'
import NewsMedia from '../component/NewsMedia'
import Gallery from '../component/Gallery'
import Career from '../component/Career'
import ContactUs from '../component/ContactUs'
import OngoingProjects from '../component/OngoingProjects'
import Maharashtra from '../component/Maharashtra'
import Gujarat from '../component/Gujarat'
import Rajasthan from '../component/Rajasthan'
import Madhyapradesh from '../component/Madhyapradesh'
import Chhattisgarh from '../component/Chhattisgarh'
import Jammu from '../component/Jammu'
import NoticeofBoardMeeting from '../component/NoticeofBoardMeeting'
import NoticesrelatedtoAGM from '../component/NoticesrelatedtoAGM'
import Newspaper from '../component/Newspaper'
import DetailsKMP from '../component/DetailsKMP'
import Statement from '../component/Statement'
import AnnualReturnundersection92 from '../component/AnnualReturnundersection92'
import Noticestohareholders from '../component/Noticestohareholders'
import SecretarialCompliance from '../component/SecretarialCompliance'
import NewspaperAdvertisement from '../component/NewspaperAdvertisement'
const Frontroutes = () => {
  return (
    <>
      <Routes>
        <Route element={<FrontLayout />}>
          <Route index element={
            <Suspense fallback={'Loading'}>
              <Home />
            </Suspense>
          } />
          <Route path='/home' element={
            <Suspense fallback={'Loading'}>
               <Home />
            </Suspense>
          } />
          <Route path='/About-us' element={
            <Suspense fallback={'Loading'}>
               <Aboutus />
            </Suspense>
          } />
          <Route path='/Chairman-Message' element={
            <Suspense fallback={'Loading'}>
               <Chairman />
            </Suspense>
          } />
          <Route path='/BoardofDirectors' element={
            <Suspense fallback={'Loading'}>
               <BoardofDirectors />
            </Suspense>
          } />
          <Route path='/KamleshSharma' element={
            <Suspense fallback={'Loading'}>
               <KamleshSharma />
            </Suspense>
          } />
          <Route path='/SureshSharma' element={
            <Suspense fallback={'Loading'}>
               <SureshSharma />
            </Suspense>
          } />
          <Route path='/VikassharmaNew' element={
            <Suspense fallback={'Loading'}>
               <VikassharmaNew />
            </Suspense>
          } />
          <Route path='/SheelaSharma' element={
            <Suspense fallback={'Loading'}>
               <SheelaSharma />
            </Suspense>
          } />
          <Route path='/Yogeshgupta' element={
            <Suspense fallback={'Loading'}>
               <Yogeshgupta />
            </Suspense>
          } />
          <Route path='/GovindMaherwal' element={
            <Suspense fallback={'Loading'}>
               <GovindMaherwal />
            </Suspense>
          } />
          <Route path='/BoardCommittees' element={
            <Suspense fallback={'Loading'}>
               <BoardCommittees />
            </Suspense>
          } />
          <Route path='/Services' element={
            <Suspense fallback={'Loading'}>
               <Services />
            </Suspense>
          } />
          <Route path='/FinancialsReports' element={
            <Suspense fallback={'Loading'}>
               <FinancialsReports />
            </Suspense>
          } />
          <Route path='/FinancialsReports' element={
            <Suspense fallback={'Loading'}>
               <FinancialsReports />
            </Suspense>
          } />
          <Route path='/AnnualReport' element={
            <Suspense fallback={'Loading'}>
               <AnnualReturn />
            </Suspense>
          } />
          <Route path='/Prospectus' element={
            <Suspense fallback={'Loading'}>
               <Prospectus />
            </Suspense>
          } />
          <Route path='/MaterialDocuments' element={
            <Suspense fallback={'Loading'}>
               <MaterialDocuments />
            </Suspense>
          } />
          <Route path='/MaterialContracts' element={
            <Suspense fallback={'Loading'}>
               <MaterialContracts />
            </Suspense>
          } />
          <Route path='/CorporateAnnouncements' element={
            <Suspense fallback={'Loading'}>
               <CorporateAnnouncements />
            </Suspense>
          } />
          <Route path='/CorporateGovernanceReport' element={
            <Suspense fallback={'Loading'}>
               <CorporateGovernanceReport />
            </Suspense>
          } />
          <Route path='/ShareholdingPattern' element={
            <Suspense fallback={'Loading'}>
               <ShareholdingPattern />
            </Suspense>
          } />
          <Route path='/InvestorContacts' element={
            <Suspense fallback={'Loading'}>
               <InvestorContacts />
            </Suspense>
          } />
          <Route path='/Policies' element={
            <Suspense fallback={'Loading'}>
               <Policies />
            </Suspense>
          } />
          <Route path='/Disclosures' element={
            <Suspense fallback={'Loading'}>
               <Disclosures />
            </Suspense>
          } />
          <Route path='/NewsMedia' element={
            <Suspense fallback={'Loading'}>
               <NewsMedia />
            </Suspense>
          } />
          <Route path='/Gallery' element={
            <Suspense fallback={'Loading'}>
               <Gallery />
            </Suspense>
          } />
          <Route path='/Career' element={
            <Suspense fallback={'Loading'}>
               <Career />
            </Suspense>
          } />
          <Route path='/OngoingProjects' element={
            <Suspense fallback={'Loading'}>
               <OngoingProjects />
            </Suspense>
          } />
          <Route path='/ContactUs' element={
            <Suspense fallback={'Loading'}>
               <ContactUs />
            </Suspense>
          } />
          <Route path='/Maharashtra' element={
            <Suspense fallback={'Loading'}>
               <Maharashtra/>
            </Suspense>
          } />
          <Route path='/Gujarat' element={
            <Suspense fallback={'Loading'}>
               <Gujarat/>
            </Suspense>
          } />
          <Route path='/Rajasthan' element={
            <Suspense fallback={'Loading'}>
               <Rajasthan/>
            </Suspense>
          } />
          <Route path='/Madhyapradesh' element={
            <Suspense fallback={'Loading'}>
               <Madhyapradesh/>
            </Suspense>
          } />
          <Route path='/Chhattisgarh' element={
            <Suspense fallback={'Loading'}>
               <Chhattisgarh/>
            </Suspense>
          } />
          <Route path='/Jammu' element={
            <Suspense fallback={'Loading'}>
               <Jammu/>
            </Suspense>
          } />
          <Route path='/NoticeofBoardMeeting' element={
            <Suspense fallback={'Loading'}>
               <NoticeofBoardMeeting/>
            </Suspense>
          } />
          <Route path='/NoticesrelatedtoAGM' element={
            <Suspense fallback={'Loading'}>
               <NoticesrelatedtoAGM/>
            </Suspense>
          } />
          <Route path='/Newspaper' element={
            <Suspense fallback={'Loading'}>
               <Newspaper/>
            </Suspense>
          } />
          <Route path='/DetailsKMP' element={
            <Suspense fallback={'Loading'}>
               <DetailsKMP/>
            </Suspense>
          } />
          <Route path='/Statement' element={
            <Suspense fallback={'Loading'}>
               <Statement/>
            </Suspense>
          } />
          <Route path='/AnnualReturnundersection92' element={
            <Suspense fallback={'Loading'}>
               <AnnualReturnundersection92/>
            </Suspense>
          } />
          <Route path='/Noticestohareholders' element={
            <Suspense fallback={'Loading'}>
               <Noticestohareholders/>
            </Suspense>
          } />
          <Route path='/SecretarialCompliance' element={
            <Suspense fallback={'Loading'}>
               <SecretarialCompliance/>
            </Suspense>
          } />
          <Route path='/NewspaperAdvertisement' element={
            <Suspense fallback={'Loading'}>
               <NewspaperAdvertisement/>
            </Suspense>
          } />
        </Route>
      </Routes>
    </>
  )
}

export default Frontroutes
