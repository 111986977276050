import React, { useState } from "react";
import Modal from "react-modal";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';



const images = [
  "/assets/img/gallery/g-1.png",
  "/assets/img/gallery/g-2.png",
  "/assets/img/gallery/g-3.png",
  "/assets/img/gallery/g-4.png",
  "/assets/img/gallery/g-5.png",
  "/assets/img/gallery/g-6.png",
  "/assets/img/gallery/g-7.png",
  "/assets/img/gallery/g-8.png",
  "/assets/img/gallery/g-9.png",
  "/assets/img/gallery/g-10.png",
  "/assets/img/gallery/g-11.png",
  "/assets/img/gallery/g-12.png",
  "/assets/img/gallery/g-13.png",
  "/assets/img/gallery/g-14.png",
  "/assets/img/gallery/g-15.png",
  "/assets/img/gallery/g-16.png",
  "/assets/img/gallery/gall-1.jpeg",
  "/assets/img/gallery/gall-2.jpeg",
  // "/assets/img/gallery/gall-3.jpeg",
  // "/assets/img/gallery/gall-4.jpeg",
];

const customStyles = {
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    width: '80%',
    // maxWidth: '400px',
    // padding: '20px',
    height :'500px',
    maxWidth : '600px',
    overflow: 'hidden',
  },
  overlay: {
    position: 'fixed',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '1000',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: "orange",
    border: 'none',
    fontSize: '20px',
    cursor: 'pointer',
    padding: '2px 8px',
    zIndex: '1001', // Ensure close button is above other elements
  },
  arrow: {
    background: 'orange',
    color: 'white', 
    border: 'none',
    fontSize: '20px',
    padding: '10px',
    cursor: 'pointer',
    position: 'absolute',
    zIndex: '1001',
    top: '50%',
    transform: 'translateY(-50%)', 
  },
};

const NextArrow = ({ onClick }) => (
  <button style={{ ...customStyles.arrow, right: '10px' }} onClick={onClick}>
    &gt;
  </button>
);

const PrevArrow = ({ onClick }) => (
  <button style={{ ...customStyles.arrow, left: '10px' }} onClick={onClick}>
    &lt;
  </button>
);

const Gallery = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);

  const openModal = (index) => {
    setCurrentSlide(index);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const settings = {
    initialSlide: currentSlide,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <>
      <section className="breadcrum">
        <div className="container-fluid">
          <div className="row">
            <div className="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
              <h1 className="text-white">Gallery</h1>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 animation-element slide-up testimonial in-view">
        <div className="container">
          <div className="row">
            {images.map((src, index) => (
              <div
                key={index}
                className="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 animation-element slide-up testimonial in-view mb-3 card-gallery"
              >
                <img
                  src={src}
                  className="w-100 height-300"
                  alt=""
                  style={{ width: "100%", cursor: "pointer" }}
                  onClick={() => openModal(index)}
                />
              </div>
            ))}
          </div>
        </div>
      </section>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Image Gallery Modal"
        ariaHideApp={false}
        style={{ content: customStyles.modal, overlay: customStyles.overlay }}
      >
        <div onClick={closeModal} style={customStyles.closeButton}>
        <i class="fas fa-times" ></i>
        </div>
        <div style={{ position: 'relative', margin: '0 -20px' }}>
          <Slider {...settings}>
            {images.map((src, index) => (
              <div key={index}>
                <img src={src} style={{ width: '100%', height :'500px' }} alt={`Slide ${index + 1}`} />
              </div>
            ))}
          </Slider>
        </div>
      </Modal>
    </>
  );
};

export default Gallery;
