import React from 'react'
import { Link } from 'react-router-dom';

const AnnualReturnundersection92 = () => {
  return (
    <>
         <section class="breadcrum-document">
        <div class="container-fluid">
            <div class="row">
                <div class="d-flex justify-content-center align-items-center text-decoration-none breadcrum_anchor">
                    <h1 class="text-white">Annual Return under section 92  </h1>
                </div>
            </div>
        </div>
    </section> 
    <section class="mt-5 animation-element slide-up testimonial in-view">
    <div class="container">
      <div class="row d-flex justify-content-center">
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2">
          <div class="card card_property_why">
            <div class="my-3">
              <img src="../assets/img/pdf (3).png" alt="" class="w-25"/>
              </div>
              <h4 class="name mt-2">2021-22</h4>
              <p class="why_para  mt-3 ">
                {/* <a href="../assets/pdf/Prospectus/Prospectus-for-uploading (1).pdf" target='_blank' class="text-decoration-none">View Pdf</a> */}
                <Link class="nav-link  px-3 py-2 why_para" to='/assets/pdf/Disclosures/MGT-7-A-2021-22-one.pdf' target='_blank' >View Pdf</Link>
              
                </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2">
          <div class="card card_property_why">
            <div class="my-3">
              <img src="../assets/img/pdf (3).png" alt="" class="w-25"/>
              </div>
              <h4 class="name mt-2">2022-23</h4>
              <p class="why_para  mt-3 ">
                {/* <a href="../assets/pdf/Prospectus/Draft-Prospectus.pdf" target='_blank' class="text-decoration-none"></a> */}
                <Link class="nav-link  px-3 py-2 why_para" to='/assets/pdf/Disclosures/MGT-7-2022-23-two.pdf' target='_blank' >View Pdf</Link>
                </p>
          </div>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12 mb-2">
          <div class="card card_property_why">
            <div class="my-3">
              <img src="../assets/img/pdf (3).png" alt="" class="w-25"/>
              </div>
              <h4 class="name mt-2">2023-24</h4>
              <p class="why_para  mt-3 ">
                <Link class="nav-link  px-3 py-2 why_para" to='/assets/pdf/Disclosures/Annual-return-comming-soon.pdf' target='_blank' >View Pdf</Link>
                </p>
          </div>
        </div>
      </div>
    </div>
  </section>
    </>
  )
}

export default AnnualReturnundersection92
