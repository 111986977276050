import React from 'react'

const Rajasthan = () => {
  return (
    <>
    <section>
      <img src="../assets/img/Rajasthan-sub.png" class="w-100 height-215" alt="" />
    </section>
         <section class="mt-5 animation-element slide-up testimonial in-view">
      <div class="container">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-12 col-sm-12 col-12 maha mb-2">
            <div class="accordion accordion-flush" id="accordionFlushExample">
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingOne">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseOne" aria-expanded="false" aria-controls="flush-collapseOne">
                    <b>Jaipur Nagar Nigam,Haritage (Civil Line Zone)</b>
                  </button>
                </h2>
                <div id="flush-collapseOne" class="accordion-collapse collapse" aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Providing Vehicles (auto-tippers) for Door-to-Door collection of segregated municipal solid waste form Civil Line Zone, Nagar Nigam Jaipur up to respective Transfer stations.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Garbage cleaning and transportation work from all the garbage depots coming under all wards of civil line zone of municipal corporation Jaipur Heritage to Sewapura
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Supply work for inviting rates of temporary unskilled laborer and supervisor through contract/agency for civil line zone municipal corporation heritage.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
             
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingUnqi">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseUnqi" aria-expanded="false" aria-controls="flush-collapseUnqi">
                  <b>Jaipur Nagar Nigam,Haritage(Adarsh Nagar Zone)
                  </b>
                  </button>
                </h2>
                <div id="flush-collapseUnqi" class="accordion-collapse collapse" aria-labelledby="flush-headingUnqi" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                    <li class="list-group-item">
                          <i class="fa fa-check"></i>Providing vehicles (auto-tippers) for Door-to-Door collection of segregated municipal solid waste from Adarsh Nagar Zone Nagar Nigam Jaipur up to respective transfer station.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Garbage cleaning and transportation work from all the garbage depots coming Under all wards of Adarsh Nagar zone of municipal corporation Jaipur heritage tolal Dungri transfer station.

                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Supply work for inviting rates of temporary unskilled laborer and supervisor through contract/agency for civil line zone municipal corporation heritage.
                      </li>
                  </ul>  
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingThree">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseThree" aria-expanded="false" aria-controls="flush-collapseThree">
                    <b>Jaipur Nagar Nigam, Haritage (Hawamahal Amer Zone)
                    </b>
                  </button>
                </h2>
                <div id="flush-collapseThree" class="accordion-collapse collapse" aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Providing vehicles (auto-tippers) for Door-to-Door collection of segregated municipal solid waste from Hawamahal Amer Zone Nagar Nigam Jaipur up to respective transfer station.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Garbage cleaning and transportation work from all the garbage depots coming under all wards of Hawamahal Amer Zone of Municipal Corporation Jaipur heritage to LalDungri transfer station.
                      </li>
                  </ul>  
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFour">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFour" aria-expanded="false" aria-controls="flush-collapseFour">
                    <b>KUMS Fruit and Vegetable Terminal Market Muhana
                    </b>
                  </button>
                </h2>
                <div id="flush-collapseFour" class="accordion-collapse collapse" aria-labelledby="flush-headingFour" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Cleaning and Collection and Transportation of waste at KUMS F and V Jaipur Terminal Market Muhana
                      </li>             
                  </ul>                  
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingFive">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseFive" aria-expanded="false" aria-controls="flush-collapseFive">
                    <b> Jaipur Nagar Nigam,Greater</b>
                  </button>
                </h2>
                <div id="flush-collapseFive" class="accordion-collapse collapse" aria-labelledby="flush-headingFive" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Auto Hooper Supply Work for Door-to-Door Waste Collection and Transportaion Work in Jagatpura Zoneof Municipal Corporation Jaipur Greater.
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Auto Hooper Supply Work for Door-to-Door Waste Collection and Transportaion Work in Jhotwara Zoneof Municipal Corporation Jaipur Greater.
                      </li>
                     
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingUnqies">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseUnqies" aria-expanded="false" aria-controls="flush-collapseUnqies">
                    <b>Rajasthan-North Western Railway</b>
                  </button>
                </h2>
                <div id="flush-collapseUnqies" class="accordion-collapse collapse" aria-labelledby="flush-headingUnqies" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                     
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Environmental Sanitation, Cleaning, Disinfection, Pest & Rodent Control Work, Door to Door Waste Collection and Garbage Removal & MSW Disposal Transportation Work for Railway Colonies of DS & WS, BGKT and Jaisalmer, for the period of Four years
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Cleaning, Sanitation, Disinfection Door to Door Waste Collection Garbage Lifting and Transportation for Garbage Disposal Works of Samdari Railway Colonies for the period of Four years.
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingSix">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseSix" aria-expanded="false" aria-controls="flush-collapseSix">
                    <b> Pali</b>
                  </button>
                </h2>
                <div id="flush-collapseSix" class="accordion-collapse collapse" aria-labelledby="flush-headingSix" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Door to door garbage collectionin Municipal Council Pali Zone-2 area using 20 No. of four-wheeler box type auto tipper having min. capacity of 1000 kg with twin compartment for dry& wet waste and monthly user charges collection under “Swachh Bharat Mission” for twoyears (extended till upto furthers order)
                      </li>
                     
                  </ul>
                   
                  </div>
                </div>
              </div>
              <div class="accordion-item">
                <h2 class="accordion-header" id="flush-headingEight">
                  <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#flush-collapseEight" aria-expanded="false" aria-controls="flush-collapseEight">
                    <b> Mount Abu</b>
                  </button>
                </h2>
                <div id="flush-collapseEight" class="accordion-collapse collapse" aria-labelledby="flush-headingEight" data-bs-parent="#accordionFlushExample">
                  <div class="accordion-body maharashtra">
                    <ul class="list-group list-group-borderless mt-2">
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Collection & Transportation of Garbage at Abuparvat Muncipal Council area under “Swatch Bharat Abhiyan”
                      </li>
                      <li class="list-group-item">
                          <i class="fa fa-check"></i>Garbage Transport Work of the Municipal Area at Mount Abu
                      </li>
                  </ul>
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-12 col-sm-12 col-12 display-none">
            <img src="../assets/img/new-img-3.png" class="w-100" alt="" />
          </div>
        </div>
      </div> 
      </section>
    </>
  )
}

export default Rajasthan
